.hero-container {
    position: relative;
    height: 85vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.hero-container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: hsla(0, 0%, 0%, 0.4);
    z-index: 1;
}

.hero-container * {
    position: relative;
    z-index: 2;
}

.hero-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 1.5rem;
    height: 100%;
}

.hero-title {
    font-size: var(--biggest-font-size);
    max-width: 900px;
    text-align: center;
    color: white;
}

.hero-subtitle {
    font-size: var(--h2-font-size);
    max-width: 900px;
    text-align: center;
    color: white;
}

.hero-button {
    padding: .7rem 2rem;
    border: 2px solid white;
    font-size: 1.3rem;
    background-color: transparent;
    outline: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.hero-button:hover {
    background-color: white;
    color: black;
}

@media screen and (min-width: 768px){
    .hero-subtitle {
        font-size: var(--h1-font-size);
    }
    
    .hero-button {
        font-size: 1.5rem;
        padding: 1rem 2.5rem;
    }
}