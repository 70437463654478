.society-section {
    padding-top: 1rem;
}

.society-content {
    background-color: var(--gray-light);
    padding: .8rem;
    margin-bottom: 1rem;
}

.society-content:nth-of-type(3) {
    background-color: var(--first-color);
}

.society-content:nth-of-type(3) .society-content-title, 
.society-content:nth-of-type(3) .society-content-paragraph {
    color: white;
}

.society-content-title {
    margin-bottom: .5rem;
}

.society-content-paragraph {
    margin-bottom: .5rem;
}

.society-list {
    display: flex;
    flex-direction: column;
    gap: .3rem;
    padding-block: .5rem;
}

@media screen and (min-width: 768px) {
    .society-section {
        padding-top: 3rem;
    }

    .society-content {
        background-color: var(--gray-light);
        padding: 1.6rem;
        margin-bottom: 1.8rem;
    }

    .society-content-title {
        margin-bottom: .7rem;
    }

    .society-content-paragraph {
        margin-bottom: .8rem;
    }

    .society-list {
        gap: 1.5rem;
    }
}