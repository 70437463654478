.contact-section {
    background: #F7F0EA;
    padding-block: 3rem;
}

.contact-container {
    position: relative;
}

.contact-title {
    font-size: var(--h2-font-size);
    margin-bottom: 1.5rem;
    text-align: center;
    max-width: 500px;
    align-self: center;
    justify-self: center;
}

.contact-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 1rem;
    align-self: center;
    justify-self: center;
    width: 100%;
}

.sub-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
}

.contact-input,
.contact-textarea {
    outline: none;
    width: 100%;
    border: 1px solid transparent;
    padding: .9rem;
    background-color: white;
    border-radius: 5px;
    font-size: calc(var(--small-font-size) + .2rem);
}

.contact-textarea {
    resize: none;
}

.contact-input::placeholder {
    color: #E0E0E0;
}

.contact-input:focus {
    border: 1px solid transparent;
    color: var(--black-color);
}

.contact-submit-button {
    padding: 1rem 2rem;
    border-radius: 8px;
    border: 1px solid #898989;
    background-color: transparent;
    font-size: var(--normal-font-size);
    font-weight: var(--font-semi);
    margin-top: 1.1rem;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.contact-submit-button:hover {
    background-color: var(--first-color);
    color: var(--white-color);
}

/* ---- BREAK POINTS ---*/
@media screen and (min-width: 767px){
    .contact-section {
        padding-block: 5rem;
    }
    
    .contact-title {
        margin-bottom: 2.5rem;
    }
    .contact-form {
        max-width: 600px;
    }
    
    .sub-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        column-gap: 1.5rem;
        row-gap: 1.3rem;
        width: 100%;
    }
}

@media screen and (min-width: 1024px) {
    .contact-form {
        max-width: 900px;
    }
}