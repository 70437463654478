.footer {
  padding-block: 3rem;
  background-color: var(--first-color);
}

.footer-logo {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  font-size: var(--h3-font-size);
  transition: color 0.3s;
}

.footer-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2.3rem;
}

.footer-desktop-data {
  display: none;
}

.footer-mobile-data {
  max-width: 250px;
}

.footer-link {
  color: var(--Beige);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  transition: background-color 0.3s;
  cursor: pointer;
}
.footer-link:hover {
  color: var(--gray);
}

/* sublist ---- */
.footer-sublist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1.2rem;
  padding-block: 2rem;
  margin-block: 3rem;
  border-top: 1px solid hsla(0, 0%, 25%, 1);
  border-bottom: 1px solid hsla(0, 0%, 25%, 1);
}

.footer-sublink {
  font-size: .9rem;
  transition: color 0.3s;
  padding-bottom: .5rem;
  color: var(--Beige);
}

.footer-sublink:hover {
  color: var(--gray);
}

.footer-contact-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .3rem;
  margin-bottom: .8rem;
}

.footer-contact-link {
  color: var(--Beige);
  font-size: calc(var(--small-font-size) + .2rem);
  text-align: center;
  max-width: 280px;
}

.footer-contact-icon {
  color: var(--Beige);
  font-size: calc(var(--small-font-size) + .3rem);
}

/*BREAKPOINTS ------------------------------------------------------- */
@media screen and (min-width: 1118px) {
  .footer-contact-link {
    max-width: initial;
  }
}

@media screen and (min-width: 1118px) {
  .footer {
    padding-block: 7rem;
  }
  
  .footer-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
  }

  .footer-mobile-data {
    display: none;
  }
  
  .footer-desktop-data {
    display: block;
  }
  
  .footer-link:hover {
    background-color: initial;
  }

  /* sublist */
  .footer-sublist {
    display: flex;
    justify-content: space-between;
    align-items: initial;
    flex-direction: row;
    padding-block: 2rem;
    margin-block: 3rem;
    padding-inline: 0;
  }
  
  .footer-sublink {
    padding-right: 0;
    border-bottom: none;
    font-size: 1rem;
  }

  .footer-sublist li:not(:last-child) .footer-sublink {
    padding-right: 3rem;
    border-right: 1px solid var(--Beige);
  }

  .footer-sublist li:last-child .footer-sublink {
    border-right: none;
    padding-right: 0;
  }
}