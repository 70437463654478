@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,304;1,304&display=swap');

:root {
  --header-height: 6rem;
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsla(213, 11%, 20%, 1);
  --first-color-alt: #4E7DDD;
  --first-color-lighten: #7FA1E6;
  --first-color-dark: #2E59AE;
  --first-color-darker: #26488D;

  --second-color: hsl(220, 68%, 54%);
  --second-color-alt: hsl(220, 68%, 54%);
  --second-colorlighten: hsl(220, 68%, 97%);
  
  --title-color: hsl(220, 48%, 28%);
  --text-color: hsla(213, 11%, 20%, 1);
  --body-color: white;
  --black-color: #090909;
  
  --gray: #ACACAC;
  --gray-alt-dim: #E6E6E6;
  --gray-light: #E0E0E0;
  --gray-lighten: #EFEFEF;
  
  --charcoal: #2F3232;
  --Beige: #F7F0EA;


  --white-color: white;
  --white-dim: #F2F2F2;

  /* ---- Font and typography ---- */
  /*.5rem = 8px | 1rem = 16px ...*/
    /*.5rem = 8px | 1rem = 16px ...*/
  --big-font-size: 2rem;
  --biggest-font-size: 3rem;
  --h1-font-size: 3rem;
  --h2-font-size: 2.1rem;
  --h3-font-size: 1.5rem;
  --normal-font-size: 1rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;

  /* Font weight */
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-semi: 700;


  /* Font weight  */
  --icon-size: 1.50rem;

  /*  z index  */
  --z-tooltip: 10;
  --z-fixed: 100;

  /* PADDING */
  --primary-padding-inline: 1.5rem;
  --secondary-padding-inline: .3rem;


  /* MAX WIDTH */
  --max-width-screen : 1400px;
}
@media screen and (min-width: 1118px) {
  :root {
    --big-font-size: 4rem;
    --biggest-font-size: 5rem;
    --h1-font-size: 3.5rem;
    --h2-font-size: 2.2rem;

    --h3-font-size: 1.6rem;
    --normal-font-size: 1.5rem;
    --small-font-size: 1rem;
    --smaller-font-size: .813rem;
    --icon-size: 1.5rem;
    --primary-padding-inline: 2.2rem;
  }
}

/* --------------------BASE -------------------- */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: "Exo 2", serif;
}

button {
  font-family: "Exo 2", serif;

}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* REUSABLE CSS CLASSES --------------------------------------------------------------------------------------------- */
.container {
  max-width: var(--max-width-screen);
  margin: auto;
  padding-inline: .7rem;
}

.wrapper {
  max-width: var(--max-width-screen);
  margin: auto;
  padding-inline: .7rem;
}

.grid {
  display: grid;
}

.section {
  padding-block: 2rem 2rem;
}

.main {
  margin-top: var(--header-height);
}

.section-title {
  color: var(--first-color);
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 100% */
  margin-inline: auto;
}

@media screen and (min-width: 768px){
  .container {
    padding-inline: 1.5rem;
  }
  
  .section-title {
    font-size: 56px;
  }
}

/* text banner -------------------------- */
.text-banner-section {
  padding-block: 2rem;
}

.text-banner-section.dark-bg {
  background-color: var(--first-color);
  padding-block: 2rem;
}

.text-banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-banner-paragraph {
  font-size: 1.2rem;
  max-width: 700px;
  text-align: center;
  line-height: 28px;
}

.text-banner-small-paragraph {
  font-size: .9rem;
  line-height: 20px;
  max-width: 500px;
  text-align: center;
}

.gray-text {
  color: #ACACAC;
}

@media screen and (min-width: 768px) {
  .text-banner-section {
    padding-block: 4rem;
  }

  .text-banner-section.dark-bg {
    background-color: var(--first-color);
    padding-block: 4rem;
  }
  
  .text-banner-container {
    height: 40vh;
  }

  .text-banner-paragraph {
    font-size: 1.5rem;
    line-height: 40px;
  }

  .text-banner-small-paragraph {
    font-size: 24px;
    line-height: normal;
  }
}

/* page banner -------------------------------------- */
.page-banner-section {
  padding-block: 2rem;
}

.page-banner-section.dark-bg {
  background-color: var(--first-color);
  padding-block: 2rem;
}

.page-banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  font-size: var(--h3-font-size);
}

/* page banner wrapper */
.page-banner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
  row-gap: 2.5rem;
}

.page-banner-dark-container {
  background-color: var(--first-color);
  padding: 2rem;
  display: flex;
  color: var(--Beige);
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.page-banner-logo {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.page-banner-text {
  max-width: 1000px;
  text-align: center;
  line-height: 28px;
}

.page-banner-text.dark-blue {
  color: var(--first-color);
}

.page-banner-text .dark-blue {
  color: var(--first-color);
}

.page-banner-text.white {
  color: var(--white-color);
}

.page-banner-text.gray {
  color: var(--gray);
}

.page-banner-text .white {
  text-align: center;
  line-height: 28px;
  color: var(--white-color);
}

.page-banner-text.medium-size {
  max-width: 1200px;
}

.page-banner-text.small {
  font-size: .9rem;
  line-height: 20px;
  max-width: 700px;
  text-align: center;
  font-weight: initial;
}

.gray-text {
  color: #ACACAC;
}

.page-banner-border {
  min-width: 250px;
  max-width: max-content;
  width: 100%;
  height: 1px;
  background-color: var(--gray);
}

@media screen and (min-width: 768px) {
  .page-banner-section {
    padding-block: 7rem;
  }

  .page-banner-section.dark-bg {
    background-color: var(--first-color);
    padding-block: 5rem;
  }

  .page-banner-text {
    line-height: 34px;
  }
  
  .page-banner-text.medium-size {
    font-size: 1.9rem;
  }

  .page-banner-text.small {
    font-size: 20px;
    line-height: normal;
  }
  
  .page-banner-border {
    min-width: 450px;
  }
} 

/* layout row +++++++++++++++++++++++++++ */
.layout-row-container {
  padding-block: 1.5rem 2.5rem;
}

.layout-row-article {
  background-color: var(--gray-lighten);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
}

.layout-row-article:only-child {
  justify-content: center;
}

.layout-row-article-content {
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:1.5rem;
}

.layout-row-download {
  border-top: 1px solid var(--gray);
  padding: 1rem 0;
}

.layout-row-download .download-text {
  color: var(--gray);
}

.layout-row-download .download-actions {
  display: flex;
  flex-direction: row;
  gap: .5rem;
  margin-top: .7rem;
}

.download-actions .download-link {
  color: var(--first-color);
}

.pointer {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .layout-row-container {
    padding-block: 3rem 3.5rem;
  }

  .layout-row-article {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.8rem 1.4rem;
    margin-bottom: 1.5rem;
  }

  .layout-row-article-content {
    justify-content: space-between;
    flex-direction: row;
    gap:3.5rem;
    text-align: left;
    width: 500px;
  }

  .layout-row-article:has(> *:only-child) {
    justify-content: center;
    text-align: center; /* Optionally center the text inside */
  }
  
  .layout-row-download {
    border-top: none;
    border-left: 1px solid var(--gray);
    padding: 1rem 1rem;
  }
}

/* portfolio --------------------------------------------------- */
.portfolio-header {
  background-color: var(--first-color);
}

.portfolio-header-container {
  padding-block: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: .6rem;
}

.portfolio-header-title,
.portfolio-header-description {
  color: white;
}

.portfolio-header-title {
  font-size: var(--h1-font-size);
}

/* portfolio content */
.portfolio-container {
  padding-block: 1rem;
}

.portfolio-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.portfolio-title {
  padding-block: .8rem;
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  font-size: var(--h2-font-size);
}

/* portfolio table */
.table-container {
  overflow-x: auto;
  margin-block: 2rem;
}

.table {
  width: 100%;
}

.table-header {
  background-color: var(--gray-lighten); /* Light gray header */
}

.table-heading {
  padding: 12px;
  text-align: left;
  background-color: var(--gray-light); /* Light gray header */
  border-bottom: 1px solid var(--gray-lighten);
}

.table-body .table-row .table-cell:first-child {
  background-color: var(--gray-lighten); /* Slightly darker gray for the first column */
}

.table-row {
  border-bottom: 1px solid var(--gray-lighten);
}

.table-cell {
  padding: 10px;
  border: 1px solid var(--gray-lighten);
  min-width: 150px;
}
/*
.table-row:nth-child(even) {
  background-color: var(--gray-lighten);  Alternate row styling 
}*/

/* portfolio list */
.portfolio-list-container {
  margin-bottom: 1.5rem;
}

.portfolio-list-title {
  margin-bottom: .5rem;
}

.portfolio-list {
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
}

/* faq */
.faq-container {
  position: relative;
  margin-block: 2rem 1.5rem;
}

.faq-item {
  margin-bottom: .5rem;
  font-size: 18px;
}

.faq-question {
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  transition: color 0.3s ease;
  background-color: var(--Beige);
  font-size: 18px;

}

.faq-question:hover {
  color: var(--gray);
}

.faq-icon {
  font-size: 20px;
}

.faq-answer {
  padding: 10px;
  margin-top: 5px;
  background: #fff;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .portfolio-container {
    padding-block: 2rem;
  }
  
  .portfolio-header-container {
    padding-block: 4.5rem;
    row-gap: .9rem;
  }

  .portfolio-content {
    gap: 2.3rem;
  }
  
  .portfolio-title {
    padding-block: 1.6rem;
  }

  /* portfolio table */
  .table-container {
    width: 100%;
    overflow-x: auto;
    margin-block: 4rem;
  }

  .table-cell {
    min-width: initial;
  }

  /* portfolio list */
  .portfolio-list-container {
    margin-bottom: 4rem;
  }

  .portfolio-list {
    row-gap: .8rem;
  }

  /* faq */
  .faq-container {
    position: relative;
    margin-block: 3.4rem 2.5rem;
  }

  .faq-item {
    margin-bottom: .9rem;
    font-size: 24px;
  }

  .faq-question {
    padding: 30px 20px;
    font-size: 32px;
  }
}





