/* HEADER ----------------------------------------------------- */
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: var(--z-fixed);
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

/* NAV */
.nav {
    width: 100%;
}

.nav-data {
    display: none;
    height: 100%;
    justify-content: space-between;
    align-items: center;
}

.nav-logo {
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
    font-size: var(--h3-font-size);
    transition: color 0.3s;
}

.nav-logo:hover {
    color: var(--first-color);
}

.nav-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 32px;
    height: 32px;
}

.nav-toggle-menu, 
.nav-toggle-close {
    font-size: var(--h1-font-size);
    color: var(--black-color);
    inset: 0;
    cursor: pointer;
    transition: opacity 0.1s, transform 0.4s;
    z-index: 1000;
}

@media screen and (max-width: 1118px) {
    .nav-data {
        display: flex;
    }
    
    .nav-desktop-data {
        display: none;
    }
    
    .nav-menu {
        position: absolute;
        left: 0;
        top: var(--header-height);
        height: calc(100vh - 3.5rem);
        overflow: auto;
        padding-block: 1.5rem 4rem;
        pointer-events: none;
        opacity: 0;
        transition: top 0.4s, opacity 0.3s;
        width: 100%;
        padding-inline: .7rem;
        background-color: var(--white-color);
    }
    
    .nav-menu::-webkit-scrollbar {
        width: 0.3rem;
    }
    
    .nav-menu::-webkit-scrollbar-thumb {
        background-color: hsl(220, 12%, 70%);
    }
}

.nav-list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.nav-link {
    color: var(--first-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    transition: background-color 0.3s;
    cursor: pointer;
}
.nav-link:hover {
    color: var(--first-color);
}

/* Show menu */
.show-menu {
    opacity: 1;
    pointer-events: initial;
}

/* Show icon */
.show-icon .nav-toggle-menu {
    opacity: 0;
    transform: rotate(90deg);
}

.show-icon .nav-toggle-close {
    opacity: 1;
    transform: rotate(90deg);
}

/* DROPDOWN ------------------------------------------------------------ */
.dropdown-button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}

.dropdown-arrow {
    font-size: 1.7rem;
    font-weight: initial;
    transition: transform 0.4s;
}

.dropdown-list {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
    padding-block: .5rem;
}

.dropdown-container {
    transition: height 0.4s;
    padding-left: .5rem;
    display: none;
    background-color: white;

}

.dropdown-open {
    display: initial;
}

.dropdown-link {
    font-size: .9rem;
    color: var(--first-color);
    transition: color 0.3s;
    border-bottom: 1px solid lightgray;
    padding-bottom: .5rem;
}

.dropdown-link:hover {
    color: var(--title-color);
}

/*BREAKPOINTS ------------------------------------------------------- */
@media screen and (min-width: 1118px) {
    .nav-toggle {
        display: none;
    }
    
    .nav-list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: space-between;
    }
    
    .nav-link:hover {
        background-color: initial;
    }
    
    /* Dropdown */
    .dropdown-link {
        padding-right: 0;
        border-bottom: none;
        font-size: 1rem;
    }
    
    .dropdown-list li:not(:last-child) .dropdown-link {
        padding-right: 3rem;
        border-right: 1px solid black;
    }

    .dropdown-list li:last-child .dropdown-link {
        border-right: none;
        padding-right: 0;
    }

    .dropdown-button {
        column-gap: 0.25rem;
    }
    
    .dropdown-container {
        height: max-content;
        position: absolute;
        left: 0;
        right: 0;
        top: 6.5rem;
        pointer-events: none;
        opacity: 0;
        border-top: 1px solid black;
        padding-left: 0;
        transition: top 0.4s, opacity 0.3s;
    }
    
    .dropdown-list {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-block: .7rem;
    }

    .dropdown-item {
        cursor: pointer;
    }

    .dropdown-item > .dropdown-container {
        top: 5.5rem;
        opacity: 0; /* Set opacity to 0 initially */
    }
    
    .show-dropdown > .dropdown-container {
        top: var(--header-height);
        opacity: 1;
        pointer-events: initial;
        cursor: initial;
    }
}
