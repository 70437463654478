.alternating-section {
    padding-block: 2rem;
}

.layout-section {
    padding-block: 2rem;
}

.alternating-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-block: 2rem;
}

.alternating-container-item {
    display: grid;
    grid-template-columns: 1.4fr 1fr;
    grid-template-rows: 400px;
    align-items: center;
    gap: 1.5rem;
    overflow: hidden;
}

.alternating-layout-item {
    display: grid;
    grid-template-columns: 1.4fr 1fr;
    align-items: center;
    gap: 1.5rem;
    overflow: hidden;
}

/* Normal layout */
.default-layout {
    grid-template-areas: "left right";
}

/* Reverse layout */
.reverse-layout {
    grid-template-areas: "left right";
    grid-template-columns: 1fr 1.4fr;
}

.alternating-content-left {
    grid-area: left;
    text-align: left;
    background-color: var(--first-color);
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    row-gap: 1.5rem;
}

.alternating-layout-left {
    text-align: left;
    background-color: var(--first-color);
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    row-gap: 1.5rem;
}

.alternating-content-left .alternating-title {
    font-size: var(--h2-font-size);
    color: white;
}

.alternating-content-left .alternating-text {
    color: white;
    line-height: normal;
    font-size: 24px;
}

.alternating-layout-left .alternating-title {
    font-size: var(--h2-font-size);
    color: white;
}

.alternating-layout-left .alternating-subtitle {
    font-size: var(--h3-font-size);
    font-weight: 300;
    color: white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 90%;
    padding-block: .5rem;
}

.alternating-layout-left .alternating-text {
    color: white;
    line-height: normal;
}

.alternating-content-right {
    grid-area: right;
    text-align: left;
    background-color: #ACACAC;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    row-gap: 1.5rem;
}

.alternating-layout-middle {
    grid-area: right;
    text-align: left;
    background-color: var(--gray-light);
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    row-gap: 1.5rem;
    margin-block: 1rem;
}

.alternating-layout-right {
    height: 100%;
    background-color: red;
}

.alternating-layout-right .alternating-layout-img {
    object-fit: fill;
    height: 100%;
    width: 100%;
}

.alternating-layout-middle .alternating-layout-img {
    object-fit: fill;
    height: 100%;
    width: 100%;
}

.alternating-content-right .alternating-title {
    font-size: var(--h2-font-size);
    color: var(--first-color);
}

.alternating-content-right .alternating-text {
    color: var(--first-color);
    line-height: normal;
    font-size: 24px;
}


/* Responsive styles */
@media (min-width: 768px) {
    .layout-section {
        padding-block: 4rem;
    }
    
    .alternating-container {
        padding-block: 5rem;
    }
    
    .alternating-container .section-title {
        margin-bottom: 1.5rem;
    }

    .alternating-layout-middle {
        margin-block: 1.5rem;
        padding-block: 4rem;
    }

    .alternating-layout-left .alternating-subtitle {
        padding-block: 1rem;
    }
}

@media (max-width: 1024px) {
    .alternating-container-item  {
        grid-template-columns: 1fr;
        grid-template-areas:
        "left"
        "right";
    }

    .alternating-layout-item {
        grid-template-columns: 1fr;
    }
}
