.hero-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80vh 80vh;
    overflow: hidden;

}

.hero-grid-content {
    background: var(--first-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 2rem;
    padding-block: 3rem;
    padding-inline: 1.2rem;
    height: 100%;
}

.hero-grid-title {
    color: var(--Beige);
    font-size: var(--h1-font-size);
    font-weight: var(--font-semi);
    font-style: normal;
    line-height: 56px;
}

.hero-grid-subtitle {
    color: var(--Beige);
}

.hero-grid-description {
    color: var(--Beige);
    line-height: 28px;
}

.hero-grid-container .first-image-box,
.hero-grid-container .second-image-box {
    width: 100%;
    height: 100%;
}

.hero-grid-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    position: relative;
}


@media screen and (min-width: 768px) {
    .hero-grid-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 80vh;
        overflow: hidden;
    }
    .hero-grid-content {
        padding-inline: 2rem;
    }
}

@media screen and (max-width: 1124px) {
    .hero-grid-container .first-image-box {
        display: none;
    }
}

@media screen and (min-width: 1124px) {
    .hero-grid-container {
        grid-template-columns: 1fr 1fr  1fr;
        grid-template-rows: 100vh;
        overflow: hidden;
    }

    /* Reverse the order for mobile devices */
    .hero-grid-container > :nth-child(1) {
        order: 2;
    }

    .hero-grid-container > :nth-child(2) {
        order: 1;
    }
    .hero-grid-container > :nth-child(3) {
        order: 3;
    }
}

