.alternating-sections {
    background-color: #f9fafb;
    padding: 2.5rem 0;
}

.alternating-sections-container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.alternating-sections-item {
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    grid-template-rows: 450px;
    align-items: center;
    gap: 1.5rem;
    overflow: hidden;
}

/* Normal layout: Text first, then image */
.grid-normal {
    grid-template-areas: "text image";
}

/* Reverse layout: Image first, then text */
.grid-reverse {
    grid-template-areas: "image text";
    grid-template-columns: 1.4fr 1fr;
}

.alternating-sections-text {
    grid-area: text;
    text-align: center;
    background-color: #ACACAC;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    row-gap: 1.5rem;
}

.section-heading {
    font-size: var(--h2-font-size);
    color: white;
}

.section-text {
    color: white;
    line-height: normal;
    font-size: 24px;
}

.alternating-sections-image {
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.section-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .section-heading {
    }
}

@media (max-width: 1024px) {
    .alternating-sections-item {
        grid-template-columns: 1fr;
        grid-template-areas:
      "image"
      "text";
    }
}
