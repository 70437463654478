.home-info-section {
    background: radial-gradient(76.19% 57.11% at 50% 50%, #666867 0%, #57595B 83.06%, #4B4C50 100%);
    padding-top: 2rem;
}

.home-info-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
}

.home-info-img {
    filter: grayscale(100%);
    transform: scaleX(-1);
    transition: transform 0.3s ease;
}

@media screen and (max-width: 768px) {
    .home-info-img {
        max-width: 350px;
    }
}

.home-info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
}

.home-info-content {
    order: 1;
}

.home-info-image {
    order: 2;
}

.home-info-title,
.home-info-description {
    color: white;
    max-width: 700px;
}

.home-info-title {
    font-size: calc(var(--h1-font-size) + .5rem);
}

@media (min-width: 768px) {
    .home-info-container {
        grid-template-columns: 1fr 2fr;
    }
    
    .home-info-content {
        order: 2;
    }

    .home-info-image {
        max-width: max-content;
        order: 1;
    }
}

/* home link --------------------------- */
.home-link-container {
    display: grid;
    grid-template-columns: 1fr;
}

.home-link-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    padding-block: 3rem;
}

.home-link-item {
    border-bottom: 2px solid lightgray;
    padding-bottom: 1rem;
    color: var(--first-color);
}

.home-link-link {
    color: var(--first-color);
    font-size: var(--h2-font-size);
}

.home-link-image {
    max-height: 800px;
    overflow: hidden;
    display: none;
}

.home-link-img {
    width: 100%;
    object-fit: contain;
}

@media (min-width: 768px) {
    .home-link-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .home-link-image {
        display: block;
    }
}